import { useDispatch, useSelector } from 'react-redux'
import store from 'src/store'
import { SetHeaderHiddenAction } from 'src/store/actions/profile'
import { inIframe, sendIframeMessage } from './iframe'
import { isScrollableY } from './scroll'
import { trackEvent } from './tracking'

export default function useShowHideHeader() {
    const dispatch = useDispatch()
    const enableHideHeader = useSelector(
        (state: State.Root) => state.profile?.company?.enable_hide_header
    )
    const previousYOffsets = {}

    function showHideHeaderScrollListener(e) {
        if (!inIframe() || !enableHideHeader) {
            return
        }

        const headerHidden = store.getState().profile?.headerHidden
        const targetName = e.target === document ? `document` : e.target.getAttribute('id')
        const newYOffset = e.target === document ? window.pageYOffset : e.target.scrollTop
        if (newYOffset <= 0 && headerHidden) {
            if (isScrollableY(e.target)) {
                if (
                    !previousYOffsets[targetName] ||
                    previousYOffsets[targetName].pathname == window.location.pathname
                ) {
                    dispatch(SetHeaderHiddenAction(false))
                    trackEvent('Parent header Show', { parent_header_from: targetName }, 'Menu')
                    sendIframeMessage('veesual_header', { show: true })
                } else if (e.target === document) {
                    window.scrollTo({ top: 1 })
                } else {
                    e.target.scrollTo({ top: 1 })
                }
            }
        } else if (
            newYOffset > 1 &&
            previousYOffsets[targetName] &&
            newYOffset > previousYOffsets[targetName].offset &&
            !headerHidden
        ) {
            dispatch(SetHeaderHiddenAction(true))
            trackEvent('Parent header Hide', { parent_header_from: targetName }, 'Menu')
            sendIframeMessage('veesual_header', { show: false })
            const elementToListen = [
                document,
                document.getElementById('layoutScrollableContent'),
                document.getElementById('layoutRightContentId'),
                document.getElementById('modalCarouselContainer'),
            ]
            for (const element of elementToListen) {
                if (element && isScrollableY(element)) {
                    if (element === document) {
                        if (window.pageYOffset <= 0) {
                            window.scrollTo({ top: 1 })
                        }
                    } else {
                        const htmlElement = element as HTMLElement
                        if (htmlElement.scrollTop <= 0) {
                            htmlElement.scrollTo({ top: 1 })
                        }
                    }
                }
            }
        }
        previousYOffsets[targetName] = { offset: newYOffset, pathname: window.location.pathname }
    }

    return showHideHeaderScrollListener
}
