import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ChangeShowFiltersAction } from 'src/store/actions/filters'

export default function useCustomHistory() {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    function historyPush(targetUrl: string, state?: any) {
        const historyState = history.location.state as any

        let backValue = historyState?.back || '/swipe'
        if (location.pathname === '/swipe' || location.pathname === '/catalog') {
            backValue = location.pathname
        }

        if (targetUrl !== '/swipe' && targetUrl !== '/catalog') {
            dispatch(ChangeShowFiltersAction(false))
        }

        history.push(targetUrl + window.location.search, {
            ...state,
            from: location.pathname,
            back: backValue === targetUrl ? '/swipe' : backValue,
        })
    }

    function historyGetBack(targetUrl?: string) {
        const historyState = history.location.state as any

        if (historyState && historyState.back) {
            return historyState.back
        }

        return targetUrl || '/swipe'
    }

    function historyGoBack(targetUrl?: string, state?: any) {
        return historyPush(historyGetBack(targetUrl), state)
    }

    return { push: historyPush, getBack: historyGetBack, goBack: historyGoBack }
}
