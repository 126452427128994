import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector } from 'src/store'
import { SetCartAddedAction } from 'src/store/actions/garment'
import { GetLookRecommendationsCall } from 'src/store/actions/look'
import { handleModalAction } from 'src/store/actions/modal'
import { trackEvent } from './tracking'

export default function useShowModal() {
    const dispatch = useDispatch()
    const company = useSelector((state: State.Root) => state.profile?.company)
    const modalOpen = useSelector((state: State.Root) => state.modal.open)
    const look = useAppSelector((state) => state.look.request)

    function showModal(
        garment_id: string | string[],
        eventProperties: any = {},
        eventCategory = 'Layout'
    ) {
        if (company.enable_recommendations && !modalOpen) {
            trackEvent('Popin Cart Opened', eventProperties, eventCategory)
            dispatch(SetCartAddedAction([].concat(garment_id)))
            dispatch(GetLookRecommendationsCall(garment_id, look.look_id))
            dispatch(handleModalAction(true))
        }
    }
    return showModal
}
