/**
 * Method that is used to load only elements that are in the threshold
 * @param itemIndex the item index we want to check if it needs to be hidden or not
 * @param currentSlide the active slide index
 * @param dataLength the length of the data used in the carousel
 * @param threshold the value we use to check if we need to hide or not. You need to add the element that are visible
 * in the carousel to this value to get the correct offset
 * @param showCurrent default case we hide the current Element because of the look underneath. Set to true if you want
 * to show it
 * @returns true if elements need to be hidden, false otherwise
 */
export const shouldHide = (
    itemIndex: number,
    currentSlide: number,
    dataLength: number,
    threshold,
    showCurrent = false
) => {
    if (itemIndex > currentSlide) {
        if (itemIndex - currentSlide <= threshold) {
            return false
        }

        if (dataLength - itemIndex + currentSlide <= threshold) {
            return false
        }

        return true
    }

    if (itemIndex < currentSlide) {
        if (currentSlide - itemIndex <= threshold) {
            return false
        }

        if (dataLength - currentSlide + itemIndex <= threshold) {
            return false
        }

        return true
    }

    if (itemIndex === currentSlide && showCurrent) {
        return false
    }

    return true
}
