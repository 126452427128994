export const isScrollableX = (el) => {
    if (el === document) {
        el = document.body
    }
    return el.scrollWidth > el.clientWidth && 'hidden' !== getComputedStyle(el).overflowX
}

export const isScrollableY = (el) => {
    if (el === document) {
        el = document.body
    }
    return el.scrollHeight > el.clientHeight && 'hidden' !== getComputedStyle(el).overflowY
}

export const isScrollable = (el) => {
    return isScrollableX(el) || isScrollableY(el)
}
