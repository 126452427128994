import React from 'react'
import { useAppSelector } from 'src/store'

import { resizeImage } from 'src/utils/image'

interface CardCarouselGarmentProps {
    garment: Models.Garment
    hide: boolean
    cardHeight?: number
    onClick: (e: React.MouseEvent) => void
    currentGarmentId?: string
}

let checkForDragCardCarousel

const CardCarouselGarment: React.FunctionComponent<CardCarouselGarmentProps> = (props) => {
    const { garment, hide, cardHeight, onClick, currentGarmentId } = props

    const garmentType = useAppSelector((state) => state.garment.type)
    const showFilters = useAppSelector((state) => state.filters.showFilters)

    const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        onClick(e)
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    // FIXME: ajout du width car si le slick se charge alors qu'au moins une image n'est pas chargee, alors rien ne s'affiche
    return (
        <div
            onMouseDown={mouseDownCoords}
            onMouseUp={(e) => handleCardClick(e)}
            className={hide ? 'card-carousel--hide' : 'card-carousel card-carousel--swipe'}
            style={{
                ...{
                    opacity:
                        currentGarmentId !== garment.garment_id ||
                        (garmentType !== garment.garment_type && showFilters)
                            ? 1
                            : 0.2,
                },
                ...(cardHeight && { height: cardHeight, width: cardHeight }),
            }}
        >
            {!hide && (
                <img
                    className={
                        'card-carousel--swipe-image' +
                        (garment.image_swipe_url ? ' swipe-format' : '')
                    }
                    src={resizeImage(
                        garment.image_swipe_url || garment.image_clipping_url || garment.image_url,
                        {
                            width: 800,
                        }
                    )}
                    style={garment.content_padding && { padding: garment.content_padding }}
                />
            )}
        </div>
    )
}

export default CardCarouselGarment
