import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { trackEvent } from 'src/utils/tracking'

const LookTracking: React.FunctionComponent = () => {
    const look = useSelector((state: State.Root) => state.look?.current)

    useEffect(() => {
        if (look) {
            trackEvent('Outfit Created', look, 'Outfit')
        }
        // eslint-disable-next-line
    }, [look])

    return null
}

export default LookTracking
