import ApiInstance from './index'

import { getQueryDefaultParams } from 'src/utils/query'

const GARMENT_ENDPOINT = `/garment/`

const GetGarmentDetailService = async (
    params: API.Req.GetGarmentDetail
): Promise<API.Resp.GetGarmentDetail> => {
    try {
        return await ApiInstance.get(`${GARMENT_ENDPOINT}${params.id}`, {
            params: getQueryDefaultParams(),
        })
    } catch (error) {
        return error.response
    }
}

export { GetGarmentDetailService }
